/* FileUpload.css */

.dropzone {
    border: 2px dashed #cccccc;
    border-radius: 4px;
    background-color: #fafafa;
    padding: 20px;
    text-align: center;
    cursor: pointer;
  }
  
  .dropzone.active {
    border-color: #2196f3;
  }
  
  .dropzone p {
    margin: 0;
  }
  
  /* Optional: Add more styles based on your design preferences */
  